// variables

$green-100: #39ff14;
$navy-100: #0a0d21;

$cursor-hand: url(./../public/images/gerdCursorHand.png);
$cursor-normal: url(./../public/images/gerdCursorNormal.png);

// common

html {
    scroll-behavior: smooth;
    overflow-x: hidden;
}

body {
    margin: 0;
    background-color: $navy-100;
    overflow-x: hidden;
}

a {
    text-decoration: none;
    color: unset;
}

.hoverPointer {
    &:hover {
        cursor: pointer;
    }
}

// admin

.tox-tinymce {
    border: 1px solid #c4c4c4 !important;
    border-radius: 4px !important;
}

// public

.public {
    // overflow-x: hidden;

    .hover-pointer,
    a,
    button {
        cursor:
            $cursor-hand 8 1,
            auto;
    }

    cursor:
        $cursor-hand 8 1,
        auto;
    cursor:
        -webkit-image-set($cursor-hand 1x) 8 1,
        auto;

    cursor:
        $cursor-normal 0 0,
        pointer;
    cursor:
        -webkit-image-set($cursor-normal 1x) 0 0,
        pointer;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    button {
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bold;
        letter-spacing: -0.34px;
        color: $green-100;
        margin: 0;
    }

    // .a-strike {
    //     &::before {
    //         left: -2.5px;
    //         top: 20px;
    //     }

    //     &::before {
    //         content: "";
    //         position: absolute;
    //         display: block;
    //         width: 0%;
    //         top: 0;
    //         height: 100%;
    //         margin-top: -0.5px;
    //         background: $green-100;
    //     }

    //     &:hover {
    //         &::before {
    //             background: $green-100;
    //             width: 103%;
    //             transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
    //         }
    //     }
    // }

    .a-strike {
        display: inline;
        text-decoration: none;
        background-image: linear-gradient($green-100, $green-100);
        background-repeat: no-repeat;
        background-position-x: left;
        background-position-y: center;
        background-size: 0% 74%;
        transition: background-size 500ms ease-in-out;

        &:hover {
            @media (min-width: 599.95px) {
                background-size: 100% 74%;
            }
        }
    }

    h1,
    h2,
    h3 {
        text-transform: uppercase;
        line-height: 0.85;
    }

    h1,
    h2 {
        font-size: 67px;
    }

    h3 {
        font-size: 37px;
    }

    h4 {
        font-size: 28px;
    }

    h5 {
        font-size: 18px;
    }

    h6 {
        font-size: 12px;
    }

    .mobile-break {
        display: none;

        @media (max-width: 599.95px) {
            display: block;
            opacity: 0;
            margin-bottom: 10px;
        }
    }

    .about-text {
        @media (max-width: 599.95px) {
            h4 {
                font-size: 18px;
            }

            h5 {
                font-size: 12px;
            }
        }
    }
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
    0% {
        transform: rotate(0);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
        transform: rotate(900deg);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
        transform: rotate(1800deg);
    }
}
@keyframes rotating {
    0% {
        transform: rotate(0);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
        transform: rotate(900deg);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
        transform: rotate(1800deg);
    }
}
.rotating {
    -webkit-animation: rotating 3s linear infinite;
    -moz-animation: rotating 3s linear infinite;
    -ms-animation: rotating 3s linear infinite;
    -o-animation: rotating 3s linear infinite;
    animation: rotating 3s linear infinite;
}
